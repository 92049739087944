.faqheading {
    margin-bottom: 30px;
    font-family: Lato, sans-serif;
    margin-left: 20px;
}

.accordion-wrapper {
    padding: 30px;
    background-color: rgb(255, 255, 255);
    
}

.applicationwrapper {
    background-color: white;
    
}

    
@media only screen and (min-width: 992px) {

    .faqheading {
        margin-bottom: 30px;
        font-family: Lato, sans-serif;
        margin-left: 20px;
    }
    
    .accordion-wrapper {
        padding: 30px;
        background-color: rgb(255, 255, 255);
        
    }
    
    .applicationwrapper {
        background-color: white;
        padding: 40px;
    }
    

}