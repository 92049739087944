.contactCont {
    margin-top: 0;
    background-color: white;
    padding: 10px;
}

.contactInfoImgRow {
    display: flex;
    justify-content: center;
    font-family: josefin sans, sans-serif;
}

.fasad-img {
    width: 80%
}

.fasadwrapper {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px 0;
}

.infowrapper {
    font-family: lato, sans-serif;
}

.headingwrapper {
    font-family: Lato, sans-serif;
    margin-bottom: 2vh;
}

.applicationcontainer {
    background-color: white;
    margin-top: 0;
    margin-bottom: 0;
}

.emaillink {
    text-decoration: none;
    color: black;
    
}

.emailcontainer {
    margin-bottom: 10px;
}


@media only screen and (min-width: 992px) {

    .contactCont {
        margin-top: 0;
        background-color: white;
        padding: 40px;
    }

    .contactInfoImgRow {
        display: flex;
        justify-content: center;
        font-family: josefin sans, sans-serif;
    }

    .fasad-img {
        width: 80%
    }

    .fasadwrapper {
        display: flex;
        justify-content: end;
        padding: 30px;
    }

    .infowrapper {
        padding: 40px;
        font-family: lato, sans-serif;
    }

    .headingwrapper {
        font-family: Lato, sans-serif;
        margin-bottom: 2vh;
    }

    .applicationcontainer {
        background-color: white;
        padding: 40px;
        margin-top: 0;
        margin-bottom: 0;
    }

}