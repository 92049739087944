.submitMaintenance {}

.btn {
    margin-top: 10px;
    width: 18vw;
    background-color: rgb(131, 0, 11);
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    border: none;
    color: white;

}

.btn:focus {
    background-color: rgb(131, 0, 11);
    opacity: 0;
}

.btn:active {
    background-color: rgb(131, 0, 11);
    opacity: 0;
}

.btn::after {
    background-color: rgb(131, 0, 11);
    opacity: 0;
}