* {
  margin: 0;
}

#root {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.container {
  overflow: hidden;
}

Link {
  text-decoration: none;
}