.estatecardlink {
    color: black;
    text-decoration: none;
}


.img-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    
}


.estate-img {
    width: auto;
    height: 25vh;
    object-fit: contain;
}

.estatecolumn {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.estatecard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    height: 35vh;
    padding: 60px;
    align-items: center;
    margin-bottom: 35px;
    font-family: Lato, sans-serif;
}

.estateinfoappartments {
    display: flex;
    justify-content: center;
}

.estateinfotitle {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 600;
}

.estatenamewrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0;
}

.estatenamecontainer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 10px;
}

.estatenametitle {
    margin-bottom: 2px;
    font-size: 13px;
    font-weight: 600;
}

.estatename{
 margin-bottom: 0;
}

.aptamountwrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.aptamounttitle {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 600;
}

.aptamounttext {
    margin-bottom: 0px;
}

.appartmentsizes {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-bottom: 5px;
}


.elevatorcontainer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}


.elevatortitle{
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 600;
        
}

.elevatorpos {
    font-size: 8px;
    justify-content: left;
    text-align: left;
}






@media only screen and (min-width: 750px) {


}

@media only screen and (min-width: 992px) {
    .img-holder {
        padding: 15px;
    }
    
    .estate-img {
        width: 25vw;
        height: 45vh;
        max-width: 20vw;
        margin: 5px 0 20px 0;
    }

    .estatecolumn {
        box-shadow: none;
    }
    
    .estatecard {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 40vw;
        height: 65vh;
        padding: 15px;
        align-items: center;
        margin-bottom: 35px;
        font-family: Lato, sans-serif;
    }
    
    .appartmentsizes {
        font-size: 14px;
    }


    .estateinfotitle {
        margin-top: 10px;
        font-size: 15px;
    }
    
    .estatenamewrapper {
        display: flex;
        margin: 0;
        font-size: 15px;

    }
    
    .estatename {
        font-size: 14px;
    }

    .aptamountwrapper {
        display: flex;
        flex-direction: row;
        
    }
    
    .aptamount1 {
        display: flex;
        justify-content: flex-start;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
    }
    
    .elevatorcontainer {
        display: flex;
    }
    

    

    
    .elevatorpos {
        font-size: 15px;
    }
    

}