.hero-container {
    height: 100vh;
}

.hero-image {
    background-image: url("/public/Img/Margaretagatan10.PNG");
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 0;
}

.hero-text {
    text-align: center;
    position: absolute;
    text-shadow: 2px 2px 4px #000000;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.hero-paragraph-wrapper {
    display: flex;
    position: relative;
    left: 35%
}

.buttons {
    background-color: rgb(92, 0, 8);
    font-family: Lato, sans-serif;
    opacity: none;
    text-decoration: none;
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 12px;
    margin: 5px;
}

.homepagebutton {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color: rgb(92, 0, 8);
    opacity: none;
    text-decoration: none;
    color: white;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 10px;
    margin: 5px;
}

.homepagebutton:hover {
    background-color: rgb(131, 0, 11);
}

.homepagebutton:focus {
    background-color: rgb(131, 0, 11);
}



.buttons:hover {
    background-color: rgb(131, 0, 11);
}

.buttons:focus {
    background-color: rgb(131, 0, 11);
}

.buttons:-webkit-appearance {
    background-color: rgb(131, 0, 11);
}

@media only screen and (min-width: 450px) {

    .hero-text {
        top: 10%;
        left: 50%;
        padding: 10px;
    }


    .hero-paragraph-wrapper {
        left: 10%
    }
}




@media only screen and (max-width: 662px) {

    .hero-text {
        top: 35%;
        left: 50%;
        padding: 10px;
    }


    .hero-paragraph-wrapper {
        left: 10%
    }
}

@media only screen and (min-width: 750px) {

    .hero-text {
        top: 89.7%;
        left: 50%;
    }


    .hero-paragraph-wrapper {
        left: 25%
    }
}

@media only screen and (min-width: 992px) {

    .hero-container {
        height: 130vh;
    }

    .buttons {
        background-color: rgb(92, 0, 8);
        opacity: none;
        text-decoration: none;
        color: white;
        padding: 7px 22px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        margin: 5px;
        width: 18vw;
    }

    .homepagebutton {
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        background-color: rgb(92, 0, 8);
        opacity: none;
        text-decoration: none;
        color: white;
        padding: 10px 25px;
        border-radius: 8px;
        font-size: 16px;
        margin: 5px;

    }


    .hero-text {
        top: 40%;
        left: 50%;
        padding: 300px;
    }

    .buttons {
        margin: 15px;
    }


    .hero-paragraph-wrapper {
        left: 35%
    }
}

@media only screen and (min-width: 1600px) {

    .hero-text {
        top: 35%;
        left: 50%;
    }

}