.MiniFooter-holder {
    background-color: #002349;
    position: relative;
    bottom: 0;
    margin-bottom: 0;
    font-family: Italianno, 'regular';
    font-size: 15px;
    font-weight: 00;
    color: white;
    overflow: hidden;
}

.footerbottomRow {
    margin: 0;
    height: 100%;
    background-color: #002349;
    overflow: hidden;
}

.miniallrightscontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
}

.footersmalltext {
    font-size: 12px;
    font-weight: 400;
    margin: 20px;
    font-family: Italianno, 'regular';
    color: #fbfbfb;
}



@media only screen and (max-width: 662px) {

    .infocontainer {
        text-align: center;
        justify-content: center;
        align-items: center;
    }


    .RmFooterHeadings {
        font-size: 14px;
        text-align: center;
    }

    .RmContactHeading {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .RmInfo {
        font-size: 10px;
    }

    .FooterLink {
        font-size: 12px;
    }

    .FooterRmInfo {
        margin-top: 20px;
    }

    .RmInfoText {
        font-size: 12px;
    }

    .FaceBookIconHolder {
        display: flex;
        flex-direction: row;
        border-top: 1px solid black;
    }

    .fbicon {
        margin-top: 10px;
        font-size: 25px;
    }

    .FacebookInfo {
        font-size: 13px;
        margin-top: 13px;
    }

    .allrightscontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .rmallrights {
        font-size: 15px;
        margin: 0;
    }

}

@media only screen and (min-width: 750px) {
    .FooterRmInfo {
        text-align: center;
    }

    .RmInfoText {
        margin: 0;
    }

}

@media only screen and (min-width: 992px) {
    .FooterRmInfo {
        text-align: center;
    }

    .RmInfoText {
        margin: 0;
    }

    .FaceBookIconHolder {
        display: flex;
        flex-direction: row-reverse;
    
    }


}