.application-wrappers {
    font-family: Lato, sans-serif;
    margin: 10px;
    background-color: white;
}

.application-list-wrapper {
    font-family: Lato, sans-serif;
    margin: 10px;

}

.application-row {
    background-color: white;
    padding: 0;
    margin: 0;
}

.interesttext {
    padding: 10px;
}

.smokinginfo {
    margin: 20px;
}

.errordescriptionwrapper {
    margin-top: 20px;
}

textarea {
    resize: none;
    width: 100%;
}

.textareacontainer {
    border: none;
}

@media only screen and (max-width: 662px) {

    .application-row {
        padding: 10px;
        margin: 0;

    }

    .interesttext {
        padding: 10px;
    }

    .application-list {
        padding: 10px;
    }

}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 992px) {
    .application-row {
        background-color: white;
        padding: 0;
        margin: 0;
    }

    .applicationrow {
        background-color: white;
        padding: 0;
        margin: 0;
    }

    .application-wrappers {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 200px 0 200px;
        align-items: center;
        
    }

    .interesttitle {
        margin-top: 30px;
    }
    
    .interesttext {
        margin-top: 5px;
        padding: 0 50px 0 50px;
    }

    .application-list-wrapper {
        padding: 0 220px 0 220px;
    }

    .application-list {
        padding: 30px;
    }

    .interestcontainer {
        padding: 0;
    }

    .smokinginfo {
        width: 80%;
    }

    .smokingtext {
        margin-bottom: 30px;
        padding: 0;
    }
}