.application-wrapper {
    background-color: white;
    
}

.maintenancecontainer {
    background-color: white;
    padding: 0;
}

.interestcontainer {
    padding: 0;
}

.maintenancecolumn {
    margin-top: 40px;
    padding: 40px 40px 0 40px;
}
.urgentmatter {
    margin-bottom: 40px;
    padding: 0 20px 0 20px;
}

@media only screen and (min-width: 992px) {

    .maintenancecolumn {
        margin-top: 40px;
        padding: 40px 200px 0 200px;
    }
   
}