.personaldatacontainer {
    padding: 0;
}

.personaldatawrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px 20px;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    margin: 0;
}

#topheading {
    margin: 20px;

}

.personaldataheading {
    margin-bottom: 5px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #333;
    margin-left: 15px;
}

.personaldatasmallheading {
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    margin-left: 15px;
}

.personaldataparagraph {
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 400;
    color: #333;
    margin: 0px 20px 15px 20px; 
    padding: 0 25px 0 25px;
}