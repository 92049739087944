.accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, .125);
}

.accordion-button:not(.collapsed) {
    color: rgb(255, 255, 255);
    background-color: #343f71;
    border: none;
    outline: none;
}


.accordion-button {
    color: rgb(253, 253, 253);
    background-color: #343f71;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    font-family: Lato, sans-serif;
}

.accordion-paragraph {
    font-size: 14px;
    font-family: Lato, sans-serif;

}

.checklistbtn {
    margin-top: 10px;
    width: 24vw;
    height: 8vh;
    background-color: rgb(92, 0, 8);
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    border: none;
    color: white;
}

.checklistbtn:hover {
    background-color: rgb(131, 0, 11);
    color: white;
}

.checklistbtn:focus {
    background-color: rgb(92, 0, 8);
}


@media only screen and (min-width: 992px) {

    .checklistbtn {
        height: 6vh;
        width: 15vw;
    }    
}