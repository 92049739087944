.specificestatecard {
    margin: 0;
    padding: 0;
}

.specificaddressheading {
    display: flex;
    justify-content: center;
    margin: 4vh 0 0 0;
    width: 100%;
    background-color: rgb(73, 0, 0);
    color: white;
    padding: 10px;
    font-family: Lato, 'regular';
}

.specificestatebanner {
    display: flex;
    justify-content: center;
    margin-top: 4vh;
}

.specificestate-img {
    width: 95%;
    height: 100%;

}

.specificestateinfocontainer {
    padding: 0 60px 0 60px;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.specificestateinfoheading {
    text-align: center;
}

.speicificaddressingressheading {
    font-size: 20px;
}

.specifiaddressinfotext {
    text-align: left;
}

.smallimagescontainer {
    display: flex;
    padding: 20px;
    justify-content: center;
}

.smallimageswrapper {
    padding: 0 10px 0 10px;
}

.specificestate-imgsmall {
    width: 25vw;
    height: 10vh;
}

.specificestateinfoandphoto {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.specificestatesmallinfocontainer {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
}

.specificestatesmallinfowrapper {
    background-color: rgb(224, 224, 224);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;

}

.specificestate-imgbottom {
    width: 35vw;
    height: 25vh;
}

.specificestatenametitle {
    font-size: 14px;
    font-weight: 600;
}

.specificaptamounttitle {
    font-size: 14px;
    font-weight: 600;
}

.specificaptamounttext {
    font-size: 12px;
}

.specificsmestatename {
    font-size: 12px;
}

.specificelevatortitle {
    font-size: 14px;
    font-weight: 600;
}

.specificelevatorno {
    font-size: 12px;
}

.otherimagewrapper {
    display: none;
}


@media only screen and (min-width: 750px) {}

@media only screen and (min-width: 992px) {

    .specificestate-img {
        width: 60vw;
        height: 100%;
    }

    .specificestate-imgsmall {
        width: 25vw;
        height: 50vh;
    }

    .specificestateinfocontainer {
        font-size: 18px;
        align-items: center;
        justify-content: center;
        margin-top: 15vh;
        margin-bottom: 15vh;
    }

    .specificestate-imgbottom {
        width: 30vw;
        height: 50vh;
        margin: 25px 0 25px 0;
    }

    .specificestatenametitle {
        font-size: 18px;
        font-weight: 600;
    }

    .specificaptamounttitle {
        font-size: 16px;
        font-weight: 600;
    }

    .specificaptamounttext {
        font-size: 18px;
    }

    .specificsmestatename {
        font-size: 18px;
    }

    .specificelevatortitle {
        font-size: 18px;
        font-weight: 600;
    }

    .specificelevatorno {
        font-size: 16px;
    }

    .otherimagewrapper {
        display: block;
    }

    .specificestatesmallinfowrapper {
        background-color: rgb(224, 224, 224);
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 15px;
        margin-left: 10px;
    }


}

@media only screen and (min-width: 1600px) {}