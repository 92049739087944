.estatecolumn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 5vh;
}

.headingwrapper {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
    
    

}

.estatelink {
    color: black;
    text-decoration: none;
}

li {
    list-style-type: none;
}

.estaterow {
    background-color: white;

}


@media only screen and (max-width: 662px) {
    .estatecolumn {
        margin: 0;
    }

}



@media only screen and (min-width: 750px) {


}

@media only screen and (min-width: 992px) {

 
}


@media only screen and (min-width: 3000px) {
    .estate-img {
        width: 20vw;
        height: 40vh;
    }
 
}