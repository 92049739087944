.Footer-holder {
    background-color: #002349;
    padding: 40px;
    border-top: 1px solid #e5e5e5;
    position: relative;
    bottom: 0;
    margin-bottom: 0;
    font-family: Italianno, 'regular';
    font-size: 15px;
    font-weight: 600;
    color: white;

}

.footerRow {
    display: flex;
    justify-content: center;
    height: 100%;
}

.FaceBookIconHolder {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.infocontainer {
    display: flex;
    justify-content: center;
}

.FooterLinks {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
    font-family: Italianno, 'regular';


}

.FooterLink {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 400;
}

.FooterLink:hover {
    /* color: #d09621; */
    color: white;
}

.RmInfoContainer {
    font-family: Italianno, 'regular';
    
}

.RmContactHeading {
    font-size: 15px;
    font-family: Italianno, 'regular';
    margin: 0;
    font-weight: 600;
    color: #ffffff;
}

.RmInfo {
    align-items: left;
    font-size: 14px;
    font-family: Italianno, 'regular';
    margin: 0 0 0 5px;
    font-weight: 600;
    /* color: rgb(218, 213, 159); */
    color: white;
}

.allrightscontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.footersmalltext {
    font-size: 12px;
    font-weight: 400;
    margin: 20px;
    font-family: Italianno, 'regular';
    color: #fbfbfb;
}

.RmInfoHeading {
    text-align: center;
}

.RmInfoText {
    font-size: 14px;
    font-weight: 200;
    margin: 0 20px 20px 0px;
    font-family: Italianno, 'regular';
    text-align: center;
}

.RmFooterHeadings {
    color: #d09621;
    font-weight: 700;
    font-size: 18px;
}

#footerlinksheading {
    
    text-align: left;
}

.fa {
    font-size: 30px;
    color: #d09621;
    margin: 0 10px 10px 10px;
}

.personaldata {
    font-size: 10px;
    font-weight: 200;
    text-decoration: none;
    margin: 20px;
    font-family: Italianno, 'regular';
    color: #fbfbfb;
}


@media only screen and (max-width: 662px) {

    .infocontainer {
        text-align: center;
        justify-content: center;
        align-items: center;
    }


    .RmFooterHeadings {
        font-size: 14px;
        text-align: center;
    }

    .RmContactHeading {
        font-size: 12px;
    }

    .RmInfo {
        font-size: 10px;
    }

    .FooterLink {
        font-size: 12px;
    }

    .FooterRmInfo {
        margin-top: 20px;
    }

    .RmInfoText {
        font-size: 12px;
    }

    .FaceBookIconHolder {
        display: flex;
        flex-direction: row;
        border-top: 1px solid black;
    }

    .fbicon {
        margin-top: 10px;
        font-size: 25px;
    }

    .FacebookInfo {
        font-size: 13px;
        margin-top: 13px;
    }

    .allrightscontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .rmallrights {
        font-size: 15px;
        margin: 0;
    }

}

@media only screen and (min-width: 750px) {
    .FooterRmInfo {
        text-align: center;
    }

    .RmInfoText {
        margin: 0;
    }

}

@media only screen and (min-width: 992px) {
    .FooterRmInfo {
        text-align: center;
    }

    .RmInfoText {
        margin: 0;
    }

    .FaceBookIconHolder {
        display: flex;
        flex-direction: row-reverse;
    
    }


}