.FormContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 40px;
}

.formheading {
    margin-bottom: 20px;
}

.formCol {
    background-color: #ffffff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Lato, sans-serif;

}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.hideform {
    display: none;
}


.submitBtnHolder {
    margin-top: 2vh;
    height: 100%;
    outline: none;
    display: flex;
    padding: 0;
    justify-content: center;
}

.button {

    background-color: rgb(92, 0, 8);
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

}

.button:focus {
    outline: none;
    background-color: blue;
}

.button:active {
    background-color: #00106d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(4px);
}

.btn:hover {
    background-color: rgb(37, 35, 104);
    color: white;
    cursor: pointer;
}


.imgHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.RmCars {
    width: 25vw;
    height: 50vh;
}

.textInputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60vw;
    height: 100%;
    margin-bottom: 15px;
    background-color: rgb(253, 253, 253);
}



.inputholder {
    margin-bottom: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
}

.textArea {
    border: 1px solid grey;
}

.checkboxcontainer {
    justify-content: center;
    align-items: center;
    text-align: left;
    height: 100%;
    margin-bottom: 10px;
    border: none;
    background-color: rgb(255, 255, 255);

}

.optionscontainer {
    background-color: rgb(255, 255, 255);
    text-align: left;
}

.appartmentsizewrapper {
    background-color: rgb(255, 255, 255);
    margin-top: 10px;
}


label {
    font-size: 12px;
    font-weight: bold;
    color: black;
    margin: 15px;
    border-bottom: 1px solid black;
}

.optionscontainer {
    padding: 0;
    margin-bottom: 15px;
}

@media only screen and (max-width: 662px) {

    .frontpagerow {
        padding: 10px;
    }

    .my-div {
        padding: 15px;
        margin-bottom: 10px;
    }

    .carouselimage {
        width: 80vw;
        height: 55vh;
        object-fit: cover;
    }

    .aboutus-heading {
        margin-bottom: 10px;
    }

    .checkboxcontainer {
        align-items: center;
        text-align: center;
    }

    .optionscontainer {
        align-items: center;
        text-align: center;
    }

    .appartmentsizewrapper {
        align-items: center;
        text-align: center;
    }

}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 2000px) {
    .textInputs {
        width: 40vw;
    }
}

@media only screen and (min-width: 3000px) {
    .textInputs {
        width: 30vw;
    }
}