.navcontainer {
    display: flex;
    background-color: #002349;
    position: relative;
    top: 0;
    z-index: 1;
    justify-content: center;
    overflow: hidden;
}

.navbar {
    background-color: #002349;
    display: flex;
    width: 100%;
    z-index: 1;
    height: 100%;
}

.headingsloganiconwrapper {
    display: flex;
    flex-direction: row;
}

.links {
    height: 100%;
    width: 100%;
    font-family: Italianno, 'regular';
    font-weight: 100;
    color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(162, 150, 18);
}

.headingcontainer {
    border-left: 2px solid rgb(162, 150, 18);
    width: 100%;
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: column;
    margin: 3px 10px 15px 8px;
}

.headingsloganiconwrapper {
    display: flex;
    padding-left: 0px;
}

.iconholder {
    display: flex;
    justify-content: center;
}


.navbar-toggler {
    font-size: 18px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.Rm-heading {
    color: white;
    font-family: Italianno, 'regular';
    text-shadow: 2px 2px 4px #000000;
    font-size: 20px;
}

.Rm-slogan {
    color: white;
    font-family: Italianno, 'regular';
    font-size: 13px;
    font-weight: 100;
    text-shadow:
        0.05em 0 black,
        0 0.05em black,
        -0.05em 0 black,
        0 -0.05em black,
        -0.05em -0.05em black,
        -0.05em 0.05em black,
        0.05em -0.05em black,
        0.05em 0.05em black;
}



.navLinks:focus {
    border-bottom: none;
}

.show {
    
    border-top: 2px solid rgb(162, 150, 18);
}

.navLinks {
    color: rgb(255, 255, 255);
    font-family: Italianno, 'regular';
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
}

.Rmheadinglink {
    text-decoration: none;
    color: white;
}


.navLinks:hover {
    color: rgb(178, 178, 14);


}


.navLinks:focus {
    color: rgb(178, 178, 14);


}


.navbar-toggler-icon {
    background-color: rgb(255, 255, 255);
    font-family: Italianno, 'regular';
    text-decoration: none;
    font-weight: 100;
}

.iconwrapper {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
}

.navbar-toggler {
    color: transparent;
    text-decoration: none;
    border: none;
    outline: none;
}







@media only screen and (min-width: 992px) {
    .navcontainer {
        display: flex;
        background-color: #002349;
        padding-bottom: 10px;
        position: relative;
        top: 0;
        z-index: 1;
        justify-content: center;
        width: 100%;

    }

    .navbar {
        background-color: #002349;
        display: flex;
        justify-content: center;
        width: 99%;
        z-index: 1;
        height: 100%;
    }

    .links {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-top: 10px;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-weight: 100;
        color: rgb(255, 255, 255);
        text-shadow: 2px 2px 4px #000000;
        border-bottom: none;

    }

    .headingcontainer {
        border-left: 2px solid rgb(162, 150, 18);
        padding: 0 10px 0 10px;
        margin-left: 15px;
    }

    .Rm-heading {
        color: white;
        font-family: Italianno, 'regular';
        text-shadow: 2px 2px 4px #000000;
        font-size: 30px;
    }

    .Rm-slogan {
        color: white;
        font-family: Italianno, 'regular';
        font-size: 14px;
        font-weight: 100;
        text-shadow:
            0.05em 0 black,
            0 0.05em black,
            -0.05em 0 black,
            0 -0.05em black,
            -0.05em -0.05em black,
            -0.05em 0.05em black,
            0.05em -0.05em black,
            0.05em 0.05em black;
    }

    .linkContainer {
        border-bottom: 2px solid rgb(162, 150, 18);
        padding: 10px;
        justify-content: space-between;
    }

    .navLinks {
        color: rgb(255, 255, 255);
        font-family: Italianno, 'regular';
        text-decoration: none;
        font-size: 16px;
        font-weight: 100;
        border-bottom: 2px solid transparent;
        text-shadow:
            0.05em 0 black,
            0 0.05em black,
            -0.05em 0 black,
            0 -0.05em black,
            -0.05em -0.05em black,
            -0.05em 0.05em black,
            0.05em -0.05em black,
            0.05em 0.05em black;
    }

    .rightnav {
        margin-right: 20px;
    }

    .leftnav {
        margin-left: 20px;
    }

    .navLinks:hover {
        color: rgb(178, 178, 14);
        transition: border-bottom 0.8s linear;
    }


    .navLinks:focus {
        color: rgb(178, 178, 14);


    }


    .navbar-toggler-icon {
        background-color: rgb(255, 255, 255);
        font-family: Italianno, 'regular';
        text-decoration: none;
        font-weight: 100;
        border-bottom: 2px solid transparent;
        text-shadow:
            0.05em 0 black,
            0 0.05em black,
            -0.05em 0 black,
            0 -0.05em black,
            -0.05em -0.05em black,
            -0.05em 0.05em black,
            0.05em -0.05em black,
            0.05em 0.05em black;
    }

    .iconwrapper {
        display: flex;
        justify-content: end;
        margin-bottom: 10px;
    }

    .navbar-toggler {
        color: transparent;
        text-decoration: none;
        border: none;
        outline: none;
        text-shadow:
            0.05em 0 black,
            0 0.05em black,
            -0.05em 0 black,
            0 -0.05em black,
            -0.05em -0.05em black,
            -0.05em 0.05em black,
            0.05em -0.05em black,
            0.05em 0.05em black;
    }

    .show {

        margin-top: 20px;
        border-top: none;
    }

}