.frontpagerow {
    background-color: WHITE;
    padding: 50px;
}

.infoHolder {
    display: flex;
    align-items: center;
}


.about-container {
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    opacity: 0;
    font-family: Lato, sans-serif;
    align-items: center;
}

.gallery-container {
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin-top: 0;
    opacity: 0;
    font-family: Lato, sans-serif;
}

.carouselwrapper {
    justify-content: center;
    align-items: center;
    display: flex;
}

.carouselimage {
    width: 30vw;
    height: 55vh;
    object-fit: cover;
}

.interestholder {
    padding: 30px;
}

.submitInterest {
    padding: 40px;
    margin-top: 10px;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    opacity: 0;
    font-family: Lato, sans-serif;

    text-shadow:
        0.05em 0 black,
        0 0.05em black,
        -0.05em 0 black,
        0 -0.05em black, ;
}



.submitMaintenance {
    padding: 40px;
    margin-top: 10px;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    font-family: Lato, sans-serif;
    text-shadow:
        0.05em 0 black,
        0 0.05em black,
        -0.05em 0 black,
        0 -0.05em black,


}

.fade-in {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(0px);
    }

    to {
        opacity: 1;
        transform: translateX(5px);
    }
}

@media only screen and (max-width: 662px) {

    .frontpagerow {
        padding: 10px;
    }

    .my-div {
        padding: 15px;
        margin-bottom: 10px;
    }

    .carouselimage {
        width: 80vw;
        height: 55vh;
        object-fit: cover;
    }

    .aboutus-heading {
        margin-bottom: 10px;
    }

    .btn {
        width: 60%;
    }

}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 1200px) {

    .about-container {
        padding: 70px;
    }

    .carouselimage {
        width: 32vw;
        height: 55vh;
        object-fit: cover;
    }

}

@media only screen and (min-width: 1600px) {

    .about-container {
        padding: 70px;
    }

    .carouselimage {
        width: 28vw;
        height: 48vh;
        object-fit: cover;
    }

}

@media only screen and (min-width: 2200px) {

    .about-container {
        padding: 70px;
    }

    .carouselimage {
        width: 20vw;
        height: 40vh;
        object-fit: cover;
    }

}

@media only screen and (min-width: 2600px) {

    .about-container {
        padding: 70px;
    }

    .carouselimage {
        width: 18vw;
        height: 40vh;
        object-fit: cover;
    }

}

@media only screen and (min-width: 3000px) {

    .about-container {
        padding: 70px;
    }

    .carouselimage {
        width: 15vw;
        height: 30vh;
        object-fit: cover;
    }

}