.hero-container {
    height: 70vh;
    overflow: hidden;
}

.hero-image {
    background-image: url("/public/Img/Margaretagatan10.PNG");
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.hero-text {
    text-align: center;
    position: absolute;
    text-shadow: 2px 2px 4px #000000;
    top: 92%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.hero-paragraph-wrapper {
    display: flex;
    position: relative;
    left: 35%
}

.herobutton {
    background-color: rgb(92, 0, 8);
    opacity: none;
}

@media only screen and (min-width: 450px) {

    .hero-text {
        top: 10%;
        left: 50%;
        padding: 10px;
    }


    .hero-paragraph-wrapper {
        left: 10%
    }
}




@media only screen and (max-width: 662px) {

    .hero-text {
        top: 35%;
        left: 50%;
        padding: 10px;
    }


    .hero-paragraph-wrapper {
        left: 10%
    }
}

@media only screen and (min-width: 750px) {

    .hero-text {
        top: 89.7%;
        left: 50%;
    }


    .hero-paragraph-wrapper {
        left: 25%
    }
}

@media only screen and (min-width: 992px) {

    .hero-text {
        top: 89.8%;
        left: 50%;
    }


    .hero-paragraph-wrapper {
        left: 35%
    }
}

@media only screen and (min-width: 1600px) {

    .hero-text {
        top: 92%;
        left: 50%;
    }

}